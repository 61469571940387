import * as React from 'react';
import {
	Dialog,
	DialogTitle,
	Button,
	DialogContent,
	Grid,
	Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { QRCode } from 'react-qrcode-logo';
import icon_tcc from '../data/icon_tcc_without_background.svg';
import { trackPromise } from 'react-promise-tracker';
import { Resident } from '../generated';
import { getNetworkApi } from './keycloak';

export default function QRCodeGreetingsForm({
	open,
	setOpen,
	residentIdData,
	emailAddress,
}) {
	const handleClose = () => {
		setOpen(false);
	};

	const [residentData, setResidentData] = React.useState({} as Resident);

	React.useEffect(() => {
		const loadResidentData = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getResidentList(
					residentIdData.tenantIdentifier,
					residentIdData.residenceIdentifier,
					residentIdData.unitIdentifier
				);
				setResidentData(
					result.find((el) => el.residentId === residentIdData.residentId)
				);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						console.log('User Unauthorized!');
					} else {
						console.log('There was an error fetching the data!');
					}
				} else {
					console.log('There was an error fetching the data!');
				}
			}
		};
		trackPromise(loadResidentData());
	}, [
		residentIdData.residentId,
		residentIdData.tenantIdentifier,
		residentIdData.residenceIdentifier,
		residentIdData.unitIdentifier,
	]);

	return (
		<React.Fragment>
			<Dialog open={open} onClose={handleClose} maxWidth={'sm'}>
				<DialogTitle>
					{'QR Code für ' +
						residentData.firstName +
						' ' +
						residentData.surname +
						' anzeigen'}
				</DialogTitle>
				<div className='new-line' />
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							E-Mail:{' '}
							{emailAddress === 'No Email Address'
								? 'There is no Email Address for this Tenant'
								: emailAddress}
							<QRCode
								value={`MATMSG:TO:${
									emailAddress === 'No Email Address'
										? 'There is no Email Address for this Tenant'
										: emailAddress
								}; SUB:Grüße an touch.connect.care - ${
									residentData.firstName
								} ${residentData.surname} [
									${residentData.residentId},
									${residentData.unitIdentifier},
									${residentData.residenceIdentifier}
								] ; BODY:Bitte innerhalb der Klammern schreiben und Betreff nicht ändern. Gerne können Sie Bilder oder kurze Videos (\<30 sec.) anhängen.\nHinweis: Verantwortliche Administratorinnen und Administratoren werden die Nachricht sichten und ggf. freigeben.\n\nGrüße von: [ IHR NAME HIER ] \nGrußworte: [ IHRE GRÜẞE HIER ];`}
								ecLevel='M'
								qrStyle='squares'
								size={500}
								logoImage={icon_tcc}
								logoOpacity={0.6}
								logoHeight={550}
								logoWidth={550}
								eyeRadius={[
									[12, 10, 0, 10], // top/left eye
									[10, 12, 10, 0], // top/right eye
									[10, 0, 10, 12], // bottom/left
								]}
							/>
						</Grid>
						<Grid item xs={12}>
							Hinweis: Verantwortliche Administratorinnen und Administratoren
							werden die Nachricht sichten und ggf. freigeben.
						</Grid>
						<Grid
							container
							direction='row'
							justifyContent='flex-end'
							alignItems='center'
							spacing={2}>
							<Grid item>
								<Button variant='contained' onClick={handleClose}>
									{<Close />} Schließen
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
		</React.Fragment>
	);
}
